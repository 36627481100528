.main-navbar{
    position: absolute !important;
    top: 70px;
    z-index: 10;
    width:100%;
}

.main-navbar .navbar-nav{
    gap:1rem;
    font-size: 1.1rem;
}

.main-navbar .nav-link{
    padding:0.5rem 1rem !important;
}

.navbar-dark .navbar-nav .nav-link.active, 
.navbar-dark .navbar-nav .show>.nav-link {
    background-color: var(--color1);
    border-radius: 0.3rem;
}

@media(max-width: 992px){
    .main-navbar .navbar-collapse{
        background-color: var(--color2);
        border-radius: 1rem;
        padding:2rem;
        box-shadow: 0 0 15px black;
    }
}