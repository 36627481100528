.slider{
   position: relative;
}

.slider .carousel-item{
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    position: relative;
}

.slider .carousel-item::after{
    content: "";
    position: absolute;
    left:0; right: 0; bottom: 0; top:0;
    background-color: var(--color2);
    opacity:0.8;
}

.slider .carousel-caption{
    bottom: auto;
    top:50%;
    z-index:1;
    transform: translateY(-50%);
}

.slider .carousel-caption h3{
    font-weight: 800;
    font-size:3rem;
}