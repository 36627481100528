.loading-page{
    background-color: var(--color2);
    width:100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loading-page .spinner-border{
    width:18rem;
    height:18rem;
    position: absolute;
    color: var(--color1);

}