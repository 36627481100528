.image-area{
    background-color: #ececec; 
    position: relative;   
}

.image-area button{
    position: absolute;
    bottom: 0;
    left:0; right:0;
    opacity: 0.8;
}

.image-area-error{
    position: absolute;
    left: 50%;
    top:50%;
    transform: translate(-50%, -50%);
}