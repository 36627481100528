.topbar {
  position: absolute;
  top: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.082);
  z-index: 10;
  width: 100%;
  padding: 1rem 0;
}

.social-bar {
  list-style: none;
  display: flex;
  font-size: 1.3rem;
  gap: 1rem;
  padding: 0;
  margin: 0;
}

.social-bar a {
  color: white;
}

.contact-bar {
  list-style: none;
  display: flex;
  gap: 1rem;
  padding: 0;
  margin: 0;
  color: white;
  justify-content: flex-end;
}

.contact-bar li {
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 0.9rem;
}

.contact-bar li svg {
  color: var(--color1);
  font-size: 2rem;
}

.contact-bar a {
  color: white;
}
