.not-found{
    text-align: center;
    position: relative;
    min-height: 40vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.not-found::after{
    content: "404";
    position: absolute;
    left:50%;
    top:50%;
    transform: translate(-50%, -50%);
    font-size: 30vw;
    font-weight: 800;
    color:#efefef;
    z-index: -1;
}