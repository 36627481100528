
.auth div[class^="col"]:first-child{
    height: 100vh;
    background-image: url("../../../assets/img/bg/login-bg.jpg");
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}


.auth div[class^="col"]:first-child::after{
    content: "";
    position: absolute;
    top:0; left:0; bottom:0; right:0;
    background-color: var(--color2);
    opacity: 0.9;

}

.auth img{
    position: relative;
    z-index: 10;
}

.auth .toolbar{
    position: absolute;
    top:1rem;
    left:1rem;
    color: var(--color1);
    z-index: 10;
    font-size: 3rem;
    cursor: pointer;
}

.auth .toolbar svg:hover{
    color: white;
}

.auth div[class^="col"]:last-child{
    padding:4rem;
}

@media(max-width: 768px){
    .auth div[class^="col"]:first-child{
        height: 100px;
    }
}